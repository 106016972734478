import { Component, NgZone, OnInit } from '@angular/core';
import { LoginService } from '../Services/LoginService/login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedvarService } from '../../sharedvar-service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Login } from './Login-Model';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ngx-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  inputType: string = 'password';
  textShow = true;
  isLoading = false;
  isUserLoggedIn = false;
  Employee: any = [];
  message: string = '';
  userid: string = '';
  password: string = '';
  disclaimer: string = "";
  subscriptions: Subscription[] = [];
  loading = false;

  constructor(private loginservice: LoginService,
    private cookieService: CookieService,
    private router: Router,
    private zone: NgZone,
    private sharedvarservice: SharedvarService,
    private cdr: ChangeDetectorRef,
    private title: Title
  ) { }

  ngOnInit(): void {
    var values = localStorage.getItem('isUserLoggedIn');
    this.title.setTitle("Airport Dashboard");
    if (values == 'false') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    if (localStorage.getItem('UserId') == '') {
      this.disclaimer = "";
    }
    else {
      this.sharedvarservice.name.subscribe(data => {
        this.disclaimer = data;
      });
    }
    this.loginservice.Admin;
    this.loginservice.User;
    this.loginservice.AccountAdmin;
  }

  public showPassword() {
    if (this.inputType == 'text') {
      this.inputType = 'password';
    } else {
      this.inputType = 'text';
    }
    if (document.getElementById("passwordICON").className === 'fa-solid fa-eye') {
      document.getElementById("passwordICON").className = "fa fa-eye-slash";
      document.getElementById("passwordICON").style.position = "absolute";
      document.getElementById("passwordICON").style.marginTop = "16px";
      document.getElementById("passwordICON").style.marginLeft = "252px";
      document.getElementById("passwordICON").style.cursor = "pointer";
    } else {
      document.getElementById("passwordICON").className = "fa-solid fa-eye";
      document.getElementById("passwordICON").style.position = "absolute";
      document.getElementById("passwordICON").style.marginTop = "16px";
      document.getElementById("passwordICON").style.marginLeft = "252px";
      document.getElementById("passwordICON").style.cursor = "pointer";
    }
  }

  public toggleMe() {
    this.isLoading = true;
    this.textShow = false;
  }

  public hideloader() {
    this.isLoading = false;
    this.textShow = true;
  }


  public CheckUser() {
    this.toggleMe();
    this.loading = true;
    let loginObj = new Login();
    loginObj.userid = this.userid;
    loginObj.password = this.password;
    let subscription = this.loginservice.getEmployee(loginObj).subscribe(
      (data: any) => {
        if (data.isError == false) {
          this.isUserLoggedIn = true;
          this.cookieService.set('token', data.token);
          console.log(data.token);
          localStorage.setItem('LoginUser', data.firstname + ' ' + data.lastname);
          localStorage.setItem('UserId', data.employeeid);
          localStorage.setItem('isUserLoggedIn', this.isUserLoggedIn ? 'true' : 'false');
          localStorage.setItem('EmployeeId', data.employeeid);
          localStorage.setItem('EmployeeName', data.firstname + ' ' + data.lastname);
          localStorage.setItem('UserTypeId', data.userTypeId);
          localStorage.setItem('designationID', data.designationId);
          localStorage.setItem('CurrentSessionId', data.currentSession);
          localStorage.setItem('ActiveforCurrentsession', data.isactiveforthissession);
          this.zone.run(() => {
            this.loginservice.getUser();
            this.loginservice.getAccountAdmin();
            this.loginservice.getAdmin();
            this.router.navigate(['/pages/dashboard']).then(()=>{
              this.loading=false;
              this.hideloader();
              this.textShow=true;
              
            })
            
          });
        } else {
          this.hideloader();
          const message = data.message;
          let remainingAttemptsHtml = '';
          if (data.message !== 'User not found') {
            remainingAttemptsHtml = `<div style="font-size: 16px; color: red;">Remaining attempts: ${data.remainingAttempt}</div>`;
          }
          const htmlContent = `<div><div style="font-size: 22px; margin-bottom: 10px;">${message}</div>${remainingAttemptsHtml}</div>`;
          Swal.fire({
            title: 'Oops...',
            html: htmlContent,
            icon: 'warning',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigateByUrl('/');
            }
          });
        }
        this.cdr.detectChanges();
      },
      (error) => {
        this.loading = false;
        console.error('Error:', error);
        this.cdr.detectChanges();
      }
    );
  
    this.subscriptions.push(subscription);
  }
  
  

  onEnterKey(event: any) {
    console.log('enter');
    if (event.keyCode === 13) {
      this.CheckUser();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
  }

}
