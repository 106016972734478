import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { Login } from "../../Login/Login-Model";
import { passwordForm } from "../../Login/passwordForm";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  public endpoint: string = environment.apiUrl;
  public designationID;""
  public Admin:number[]=localStorage.getItem("Admin")==null?[]: localStorage.getItem("Admin").split(",").map(function(str) {
    return parseInt(str); });
    public User:number[]=localStorage.getItem("Admin")==null?[]: localStorage.getItem("Admin").split(",").map(function(str) {
      return parseInt(str); });
      public AccountAdmin:number[]=localStorage.getItem("AccountAdmin")==null?[]: localStorage.getItem("AccountAdmin").split(",").map(function(str) {
        return parseInt(str); });
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.getAdmin();
    this.getUser();
    this.getAccountAdmin();
  }
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getToken(),
    }),
  };

  //#region Get Admin List
  public getAdmin()
  {
    this.http.get(this.endpoint+'/Login/GetAdminDesignationList').subscribe((res:any)=>{
      if(res.isError==false)
      {
        var Adminlist=JSON.parse(res.data);
        console.log(Adminlist);
        this.Admin=Adminlist.map(el=>{
        return el.rid;
      })
      localStorage.setItem("Admin",this.Admin.toString());
      }
    })
  }
//#endregion
  //#region Get AccountAdmin List
  public getAccountAdmin()
  {
    this.http.get(this.endpoint+'/Login/GetAccountAdminList').subscribe((res:any)=>{
      if(res.isError==false)
      {
        var AccountAdminlist=JSON.parse(res.data);
        console.log(AccountAdminlist);
        this.AccountAdmin=AccountAdminlist.map(el=>{
        return el.rid;
      })
      localStorage.setItem("AccountAdmin",this.AccountAdmin.toString());
      }
    })
  }
//#endregion

  //#region Get User List
  public getUser()
  {
    this.http.get(this.endpoint+'/Login/GetUserDesignationList').subscribe((res:any)=>{
      if(res.isError==false)
      {
        var UserList=JSON.parse(res.data);
        console.log(UserList);
        this.User=UserList.map(el=>{
        return el.rid;
      })
      localStorage.setItem("User",this.User.toString());
      }
    })
  }
//#endregion

//#region Fetch Auditor List
getOneAuditor(EmployeeId:number):Observable<any> {
  return this.http.get(`${this.endpoint}/Auditor/fetchAuditorListbyId?EMPLOYEEID=${EmployeeId}`).pipe(
    map((response: any) => {
      console.warn(response)
      const auditors = JSON.parse(response.data);
      return { auditors };
    })
  );
}
//#endregion


  //#region  GetToken
  public getToken() {
    return this.cookieService.get("token");
  }
  //#endregion

  //#region Get Employee
  public getEmployee(login: Login): Observable<any> {
    this.httpOptions.headers.append(
      "Authorization",
      "Bearer " + this.cookieService.get("token")
    );
    return this.http
      .post<any>(
        this.endpoint + "/Login/CheckUserCredentials",
        login,
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  //#endregion

  //#region  Handle error
  public handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status} Message: ${error.message}`;
    }
    //this.openModal(errorMessage);
    return throwError(errorMessage);
  }
  //#endregion

  //#region Token Expired
  tokenExpired(token: string) {
    if (token != "") {
      console.log(token);
      const expiry = JSON.parse(
        atob(decodeURIComponent(token.split(".")[1]))
      ).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    } else {
      return true;
    }
  }
  //#endregion

  //#region update Password
  public updatePassword(
    empid: number,
    Password: string,
    basic: passwordForm
  ): Observable<any> {
    this.httpOptions.headers.append(
      "Authorization",
      "Bearer " + this.cookieService.get("token")
    );

    return this.http
      .post<any>(
        this.endpoint +
          "/Login/UpdatePassword?EmployeeId=" +
          empid +
          "&Password=" +
          Password,
        basic,
        this.httpOptions
      )
      .pipe(retry(1));
  }
}
