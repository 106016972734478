import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/pages/dashboard',
    home: true,
  },
  // {
  //   title: 'E-commerce',
  //   icon: 'shopping-cart-outline',
  //   link: '/pages/dashboard',
  //   home: true,
  // },
  // {
  //   title: 'IoT Dashboard',
  //   icon: 'home-outline',
  //   link: '/pages/iot-dashboard',
  // },
  {
    title: 'Airport',
    icon: 'briefcase-outline',
    link: '/pages/airports',
  },
  {
    title: 'Auditor',
    icon: 'person-outline',
    link: '/pages/auditors',
  },
  {
    title: 'Project',
    icon: 'monitor-outline',
    link: '/pages/projects',
  },
  {
    title: 'Project Breakdown',
    icon: 'layers-outline',
    link: '/pages/project_breakdown',
  },

  {
    title: 'News',
    icon: 'cast-outline',
    link: '/pages/news',
  },
  {
    title: 'Verification History',
    icon: 'refresh-outline',
    link: '/pages/verification_history',
  },

  {
    title: 'Settings',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Update Password',
        icon:'lock-outline',
        link: '/pages/settings',
      },

    ],
  }
  // {
  //   title: 'Settings',
  //   icon: 'settings-2-outline',
  //   link: '/pages/settings',
  // },
];
