<div class="logo-container">
  <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>
  <a href="#" (click)="navigateHome()">
    <img src="assets/images/logo.png" alt="Logo" class="logo-image ml-4" height="40px" width="170px">
  </a>
</div>


<!-- <div class="dropdown">
  Profile
  <div class="dropdown-content">
    <a href="#" (click)="logout()">Logout</a>
  </div> -->
<div class="header-container">
  <div>
    <p class="mt-3 mr-2" style="color: #727272;"><b>Howdy, {{loggedinUser}}</b></p>
  </div>

  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <div class="col profileNav" style="padding: 0;">
        <img [src]="this.Image" onerror="this.src='../../../../assets/images/man.png'"alt="alternative" style="border-radius: 50%; height:36px; width:36px;">

        </div>

      <!-- <nb-user [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.picture">
      </nb-user> -->
    </nb-action>
  </nb-actions>
  <nb-action class="control-item" icon="power" data-toggle="tooltip" title="Logout" (click)="logout()"></nb-action>


</div>
