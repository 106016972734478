import {Injectable,Injector} from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Injectable()

export class JWTInterceptor implements HttpInterceptor{
    constructor(private injector:Injector,private cookieService:CookieService)
    { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isUserLoggedIn=localStorage.getItem('isUserLoggedIn')
        const isApiUrl=req.url.startsWith(environment.apiUrl);
        if(isUserLoggedIn && isApiUrl)
        {
            req=req.clone({
                setHeaders:{'Authorization':'Bearer '+this.cookieService.get('token')}
            })
        }
        return next.handle(req);
    }
}
